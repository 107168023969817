<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="leavesOutput"
      item-key="meta.id"
      multi-sort
      sort-by="duration.start.timestamp"
      :sort-desc="true"
      :items-per-page="15"
      :footer-props="footerProps"
      :search="search"
      class="elevation-2"
      max-height="800px auto"
      @click:row="routeDetails"
      no-data-text="Keine Abwesenheiten vorhanden"
      no-results-text="Keine Abwesenheiten gefunden"
    >
      <template v-slot:top>
        <v-card flat>
          <v-card-subtitle>
            <v-row dense>
              <v-col v-if="showAllLeaves" cols="12" sm="4" md="4">
                <v-text-field
                  v-model="search"
                  outlined
                  append-icon="mdi-magnify"
                  label="Nach Personen suchen"
                  :disabled="leavesPersonFilter === 'own'"
                  hide-details="auto"
                  clearable
                  :dense="$vuetify.breakpoint.smAndDown"
                ></v-text-field>
              </v-col>
              <v-col
                v-if="showAllLeaves"
                :class="$vuetify.breakpoint.smAndUp ? 'ml-2' : ''"
              >
                <span class="mr-4">Filter nach Typ</span>
                <v-chip-group
                  v-model="leavesPersonFilter"
                  mandatory
                  active-class="primary--text"
                >
                  <v-chip
                    label
                    value="all"
                    class="transparent-background font-weight-medium mr-2"
                    >Alle
                  </v-chip>
                  <v-chip
                    label
                    value="own"
                    class="transparent-background font-weight-medium"
                    >Eigene</v-chip
                  >
                </v-chip-group>
              </v-col>
              <v-col>
                <span class="mr-4">Filter nach Status</span>
                <v-chip-group
                  v-model="leavesStateFilter"
                  mandatory
                  active-class="primary--text"
                >
                  <v-chip
                    label
                    value="all"
                    class="transparent-background font-weight-medium mr-2"
                    >Alle</v-chip
                  >
                  <v-chip
                    label
                    value="active"
                    class="transparent-background font-weight-medium mr-2"
                    >Aktiv</v-chip
                  >
                  <v-chip
                    label
                    value="upcoming"
                    class="transparent-background font-weight-medium mr-2"
                    >Bevorstehend</v-chip
                  >
                </v-chip-group>
              </v-col>
              <!-- <v-col>
                            <span class="mr-4">Filter nach Person</span>
                            <v-autocomplete
                              :items="[]"
                              outlined
                              persistent-hint
                              return-object
                              label="Person auswählen"
                              item-text="displayName"
                              item-value="uid"
                              hide-details="auto"
                              dense
                            ></v-autocomplete>
                          </v-col> -->
            </v-row>
          </v-card-subtitle>
        </v-card>
        <v-divider></v-divider>
      </template>

      <template v-slot:group.header="{ items, isOpen, toggle }">
        <th :colspan="headers.length">
          <v-icon @click="toggle"
            >{{ isOpen ? "mdi-chevron-up" : "mdi-chevron-down" }}
          </v-icon>
          {{ stripPrefix(items[0].groupBy) }}
        </th>
      </template>
      <template v-slot:[`item.user.displayName`]="{ item }">
        <div class="d-flex flex-column">
          <span
            class="d-block font-weight-semibold text--primary text-truncate"
            >{{ item.user.displayName }}</span
          >
          <!-- <small class="grey--text">{{ item.group.title }}</small> -->
          <!-- <small class="grey--text">Benutzergruppe</small> -->
        </div>
      </template>

      <template v-slot:[`item.duration.start.timestamp`]="{ item }">
        {{ item.duration.start.formattedTimestamp }}
      </template>
      <template v-slot:[`item.duration.end.timestamp`]="{ item }">
        <span>{{ item.duration.end.formattedTimestamp }}</span>
        <br v-if="item.duration.end.isOpen" />
        <v-chip
          v-if="item.duration.end.isOpen"
          x-small
          class="font-weight-medium red--text text--darken-4"
          color="red lighten-4"
          >Ende offen</v-chip
        >
      </template>
      <template v-slot:[`item.progress`]="{ item }">
        <date-progress-bar
          :start="item.duration.start.timestamp"
          :end="item.duration.end.timestamp"
          :color="`${getStatusColor(item.status)} lighten-4`"
        ></date-progress-bar>
      </template>
      <!-- <template #[`item.full_name`]="{ item }">
        <div class="d-flex flex-column">
          <span
            class="d-block font-weight-semibold text--primary text-truncate"
            >{{ item.full_name }}</span
          >
          <small>{{ item.post }}</small>
        </div>
      </template> -->
      <template #[`item.status`]="{ item }">
        <leave-status-chip :status="item.status" small> </leave-status-chip>
      </template>
      <template #[`item.reason.id`]="{ item }">
        <leave-reason-chip :reason="item.reason" small></leave-reason-chip>
      </template>
      <template v-slot:no-data>
        <div
          style="
            height: 300px;
            display: flex;
            justify-content: center;
            align-items: center;
          "
        >
          <p class="subtitle-1">Keine Abwesenheiten vorhanden.</p>
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { auth } from "@/firebase";
import DateProgressBar from "@/components/_systemwide/DateProgressBar.vue";
import LeaveStatusChip from "@/components/availability/leaves/LeaveStatusChip.vue";
import LeaveReasonChip from "@/components/availability/leaves/LeaveReasonChip.vue";
import { AVAILABILITY_LEAVES_read_all } from "@/data/permission-types.js";
export default {
  name: "leaves-data-table",
  props: {
    leaves: {
      type: Array,
      required: true,
    },
  },
  components: {
    LeaveStatusChip,
    LeaveReasonChip,
    DateProgressBar,
  },
  data() {
    return {
      search: "",
      leavesPersonFilter: "own",
      leavesStateFilter: "active",
      currentUserUid: auth.currentUser.uid,
      footerProps: {
        "items-per-page-options": [15, 25, 50, 100, -1],
      },
    };
  },
  computed: {
    showAllLeaves() {
      return this.$store.getters["organization/checkPermissionByID"](
        `${AVAILABILITY_LEAVES_read_all}`
      );
    },
    leavesOutput() {
      return this.leaves.filter((leave) => {
        if (this.leavesPersonFilter === "own") {
          return leave.user.uid === this.currentUserUid;
        }
        return true;
      });
    },
    headers() {
      const headersArray = [];

      if (this.leavesPersonFilter === "all") {
        headersArray.push({
          text: "Name",
          value: "user.displayName",
        });
      }

      headersArray.push(
        {
          text: "Status",
          value: "status",
          filter: (value) => {
            if (!this.leavesStateFilter) return true;
            if (this.leavesStateFilter === "all") return true;
            return value === this.leavesStateFilter;
          },
        },
        { text: "Startzeitpunkt", value: "duration.start.timestamp" },
        { text: "Endzeitpunkt", value: "duration.end.timestamp" },
        { text: "Fortschritt", value: "progress", hide: "smAndDown" },
        { text: "Grund", value: "reason.id" }
      );

      return headersArray.filter(
        (h) => !h.hide || !this.$vuetify.breakpoint[h.hide]
      );
    },
  },
  methods: {
    routeDetails(item) {
      this.$router.push({
        name: "availability-leaves-details",
        params: {
          organizationId: this.$route.params.organizationId,
          itemId: item.meta.id,
        },
      });
    },
    getStatusColor(statusId) {
      switch (statusId) {
        case "active":
          return "red";
        case "upcoming":
          return "orange";
        case "finished":
          return "green";
        default:
          return "grey";
      }
    },
  },
};
</script>
